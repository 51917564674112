
import Swiper from "swiper";

import { Navigation } from 'swiper/modules';

export default function mobileCarousel(sliderClass, arrowClass = false, perView = 1.4, spaceBetweenSlides = 16, isParentArrow = true, windowWidth = 991.98, isLoop = true) {
    const sliderSections = document.querySelectorAll(`.${sliderClass}`);

    Swiper.use([Navigation]);
    let slidersArray = [];


    const initSwiper = () => {
        sliderSections.forEach((section, i) => {
            let init = false;
            section.classList.add(`${sliderClass}-${i}`);
            if (arrowClass) {
                if (isParentArrow) {
                    section.parentElement.querySelector(`.${arrowClass}--next`).classList.add(`${arrowClass}--next-${i}`);
                    section.parentElement.querySelector(`.${arrowClass}--prev`).classList.add(`${arrowClass}--prev-${i}`);
                } else {
                    section.querySelector(`.${arrowClass}--next`).classList.add(`${arrowClass}--next-${i}`);
                    section.querySelector(`.${arrowClass}--prev`).classList.add(`${arrowClass}--prev-${i}`);
                }
            }
            if (!init && window.innerWidth < windowWidth) {
                init = true;
                if (section.querySelector('.swiper-wrapper')) {
                    const heroCarousel = new Swiper(`.${sliderClass}-${i}`, {
                        slideActiveClass: 'is-active',
                        loop: isLoop,
                        slidesPerView: perView,
                        spaceBetween: spaceBetweenSlides,
                        navigation: {
                            nextEl: arrowClass ? `.${arrowClass}--next-${i}` : false,
                            prevEl: arrowClass ? `.${arrowClass}--prev-${i}` : false,
                        },
                    });
                    slidersArray.push(heroCarousel);
                }
            } else if (window.innerWidth > windowWidth) {
                slidersArray.forEach((item => {
                    item.destroy();
                }))
                init = false;
            }
        })
    }

    initSwiper();
    window.addEventListener('resize', initSwiper);
}